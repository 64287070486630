import React from "react";

import LOGO from "../../Assets/img/logo/principale.png";
import { useStyles } from "./style";

interface ILogo {
  style?: React.CSSProperties;
}
const Logo: React.FC<ILogo> = ({ style }) => {
  const styles = useStyles();
  return <img src={LOGO} alt="logo" className={styles.img} style={style} />;
};
export default Logo;
