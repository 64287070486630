import React, { useContext } from "react";

import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../Components/Route/ProtectedRoute/ProtectedRoute";
import AuthContext from "../../Context/AuthContext";
import AddCommand from "../Command/AddCommand/AddCommand";
import NewAddCommand from "../Command/AddCommand/NewAddCommand";
import Contact from "../Contact/Contact";
import Dashboard from "../Dashboard/Dashboard";
import HomePage from "../Dashboard/DefaultPage";
import FactureView from "../DisplayFactures/FactureView";
import Factures from "../Facture/Factures";
import Forgotpassword from "../ForgotPassword/Forgotpassword";
import Help from "../Help/Help";
import DetailsHistory from "../Historique/DetailsHistory/DetailsHistory";
import Historique from "../Historique/Devis/DevisHistorique";
import Login from "../Login/Login";
import Planifications from "../Planification/Planifications";
import EditProfil from "../Profil/Edit/EditProfil";
import Profil from "../Profil/Profil";
import ResetPassword from "../ResetPassword/ResetPassword";
import Rupture from "../Ruptures/Rupture";
import Signup from "../Signup/Signup";
import Suivi from "../Suivi/Suivi";

const Router: React.FC = () => {
  const { token } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgotpass" element={<Forgotpassword />} />
      <Route path=":id/resetpassword" element={<ResetPassword />} />
      <Route path="/facture/:id/view" element={<FactureView />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute token={token} redirectPath="/" />}
      >
        <Route element={<Dashboard />}>
          <Route index element={<HomePage />} />
          <Route path="addtask" element={<NewAddCommand />} />
          <Route path=":type/order" element={<NewAddCommand />} />
          <Route path=":type/devis" element={<NewAddCommand />} />
          <Route path=":type/order/add" element={<AddCommand />} />
          <Route path=":type/devis/add" element={<AddCommand />} />
          <Route path="suivi/:activ/:id" element={<DetailsHistory />} />
          <Route path="suivi/:activ/:id/edit" element={<Planifications />} />
          <Route path="suivi/:activ/planner" element={<Planifications />} />
          <Route path="suivi" element={<Suivi />}>
            <Route path=":activ" element={<Historique />} />
          </Route>
          <Route path="controle" element={<Rupture />} />
          <Route path="profil" element={<Profil />} />
          <Route path="profil/edit" element={<EditProfil />} />
          <Route path="fact" element={<Factures />} />
          <Route path="helpers" element={<Help />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
