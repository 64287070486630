/* eslint-disable no-nested-ternary */
import React from "react";

import {
  ApolloCache,
  FetchResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  AppBar,
  Button,
  Divider,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import Iframe from "react-iframe";
import { useNavigate, useParams } from "react-router-dom";

import CustomModal from "../../../Components/CustomModal/CustomModal";
import Empty from "../../../Components/EmptyData/Empty";
import Loading from "../../../Components/Loading/Loading";
import {
  SetTitleByPathName,
  TypePatient,
  checkTypeFile,
} from "../../../Config/constant";
import {
  Maybe,
  Mutation,
  MutationDeleteRenouvellementArgs,
  MutationUpdateValidateDevisArgs,
  Query,
  QueryGetListCommandesArgs,
  QueryResendCommandArgs,
} from "../../../graphql/__generated__/types";
import { DELETE_RENEWAL_ORDER } from "../../../graphql/command/mutation";
import { DO_RENEWAL, GET_ORDER_BY_ID } from "../../../graphql/command/query";
import { UPDATE_VALIDATE_DEVIS } from "../../../graphql/devis/mutation";
import ModalConfirmation from "../../Command/common/ModalConfirmation";
import ModalConfirmationDevis from "../../Command/common/ModalConfirmationDevis";
import CustomField from "../Common/Feature/CustomField";
import { stylesX, useStyles } from "./style";

const DetailsHistory: React.FC = () => {
  const style = useStyles();
  const { activ, id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalValidation, setOpenModalValidation] = React.useState(false);
  const [openValidateCancel, setopenValidateCancel] = React.useState(false);
  const [openValidateDevisCancel, setopenValidateDevisCancel] =
    React.useState(false);

  const [openConfirm, setopenConfirm] = React.useState(false);
  const [globalID, setglobalID] = React.useState<Maybe<number> | undefined>(
    undefined,
  );

  const { data, loading, refetch } = useQuery<Query, QueryGetListCommandesArgs>(
    GET_ORDER_BY_ID,
    {
      variables: {
        input: {
          skip: 0,
          take: 10,
          TypeCommandes: Number(activ) || 3,
          search: id,
        },
      },
      fetchPolicy: "cache-first",
    },
  );

  const [SendRenewCommand, { loading: loadRenewal }] = useLazyQuery<
    Query,
    QueryResendCommandArgs
  >(DO_RENEWAL);

  const [UpdateValidation, { loading: loadingUpdateValidate }] = useMutation<
    Mutation,
    MutationUpdateValidateDevisArgs
  >(UPDATE_VALIDATE_DEVIS);

  const [DeleteOrder, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteRenouvellementArgs
  >(DELETE_RENEWAL_ORDER);

  const OnConfirmDelete = (idParams?: Maybe<number> | undefined) => {
    setOpenModalDelete((prev) => !prev);
    DeleteOrder({
      variables: {
        input: {
          id: Number(idParams),
        },
      },
      update: (cache) => {
        const allQuery = cache.readQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              skip: 0,
              take: 50,
              TypeCommandes: 1,
            },
          },
        });

        const dataUpdated = allQuery?.GetListCommandes?.filter(
          (i) => i?.id !== Number(idParams),
        );
        const count = allQuery?.GetListCommandes?.length;
        const taken = count ? (count >= 50 ? count : 50) : 0;
        cache.writeQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              skip: 0,
              take: taken,
              TypeCommandes: 1,
            },
          },
          data: {
            GetListCommandes: dataUpdated,
          },
        });
        navigate(-1);
      },
    });
  };

  const onUpdateValidation = (idParams?: Maybe<number> | undefined) => {
    setOpenModalValidation((prev) => !prev);
    UpdateValidation({
      variables: {
        input: {
          orderId: Number(idParams),
        },
      },
      update: (
        cache: ApolloCache<any>,
        result: Omit<FetchResult<Mutation>, "context">,
      ) => {
        setopenValidateDevisCancel((prev) => !prev);
        const allQuery = cache.readQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: Number(activ),
            },
          },
        });

        const dataUpdated = allQuery?.GetListCommandes?.map((i) => {
          if (i?.id === idParams) {
            return {
              ...result.data?.UpdateValidateDevis,
            };
          }
          return {
            ...i,
          };
        });
        cache.writeQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: Number(activ),
            },
          },
          data: {
            GetListCommandes: dataUpdated,
          },
        });
        refetch();
      },
    });
  };

  const onRenewal = (idParams?: Maybe<number> | undefined) => {
    setopenConfirm((prev) => !prev);
    setglobalID(idParams);
  };

  const onConfirm = () => {
    setopenConfirm((prev) => !prev);
    SendRenewCommand({
      variables: {
        input: {
          id: Number(globalID),
        },
      },
      onCompleted: (res: Query) => {
        if (res.ResendCommand) {
          setopenValidateCancel((prev) => !prev);
        }
      },
    });
  };

  return (
    <div className={style.container}>
      <div className={style.boxTitle}>
        <Loading
          loading={loadingDelete || loadingUpdateValidate || loadRenewal}
        />
        <CustomModal
          open={openConfirm}
          setOpen={setopenConfirm}
          title="Renouvellement d'une commande"
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>
                Vous confirmez qu'une nouvelle commande sera passée auprès du
                préparatoire avec cette ordonnance aux dates indiquées
              </Typography>
            </div>
          }
          confirmAction={{
            text: "Confirmer",
            onClick: onConfirm,
          }}
          cancelAction={{
            text: "Annuler",
            onClick: () => {
              setopenConfirm(false);
            },
          }}
        />
        <CustomModal
          open={openValidateDevisCancel}
          setOpen={setopenValidateDevisCancel}
          content={<ModalConfirmationDevis />}
          confirmAction={{
            text: "Ok",
            style: {
              background: "#ffffff",
              boxShadow:
                "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
              color: "#000",
              fontWeight: "bold",
            },
            onClick: () => {
              navigate(-1);
              setopenValidateDevisCancel(false);
            },
          }}
        />
        <CustomModal
          open={openValidateCancel}
          setOpen={setopenValidateCancel}
          content={<ModalConfirmation type="3" />}
          confirmAction={{
            text: "Ok",
            style: {
              background: "#ffffff",
              boxShadow:
                "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
              color: "#000",
              fontWeight: "bold",
            },
            onClick: () => {
              navigate(-1);
              setopenValidateCancel(false);
            },
          }}
        />

        <Typography
          style={{ fontSize: "24px", color: "#616161", fontWeight: "600" }}
        >
          {SetTitleByPathName(activ || "3")}
        </Typography>

        {activ === "1" && (
          <Typography style={{ color: "#909090" }}>
            Vous devez renseigner la(es) date(s) à la(aux)quelle(s) nous
            recevrons votre (vos) demande(s)
          </Typography>
        )}
      </div>
      {loading && (
        <>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </>
      )}
      <div
        style={{
          overflow: "auto",
          height: "calc(100vh - 282px)",
          paddingRight: 20,
        }}
      >
        {!loading &&
          data?.GetListCommandes &&
          data?.GetListCommandes?.map((command, indexCount) => {
            if (command === null) {
              return null;
            }
            const info = JSON.parse(command.data);
            const field = info.fieldname
              ? info.fieldname
              : info.type_commande === 2
              ? "Devis n°"
              : "Commande n°";
            const startDateRenouvellement = command?.renouvellement?.date;
            const rulesRenouvellement =
              command?.renouvellement?.rrule &&
              JSON.parse(command?.renouvellement?.rrule);
            const endDateRenouvellement =
              rulesRenouvellement?.dateRepeatList.slice(-1);
            const listDatePlanification = rulesRenouvellement?.dateRepeatList;
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {indexCount !== 0 && (
                  <Divider style={{ margin: "10px 0 20px 0" }} />
                )}
                <div className={style.boxContent} key={command?.id}>
                  <CustomModal
                    open={openModalValidation}
                    setOpen={setOpenModalValidation}
                    title="Validation d'un devis"
                    confirmAction={{
                      text: "Confirmer",
                      onClick: () => onUpdateValidation(command?.id),
                    }}
                    cancelAction={{
                      text: "Annuler",
                      onClick: () => {
                        setOpenModalValidation((prev) => !prev);
                      },
                    }}
                    content={
                      <div>
                        <Typography>
                          Êtes vous sûr de vouloir valider ce devis ?
                        </Typography>
                      </div>
                    }
                  />
                  <CustomModal
                    open={openModalDelete}
                    setOpen={setOpenModalDelete}
                    title="Annulations d'un renouvellement"
                    content={
                      <div>
                        <Typography>
                          Êtes vous sûr de vouloir annuler le renouvellement ?
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: "10px 0",
                            textTransform: "unset",
                          }}
                        >
                          <Button
                            style={{
                              marginRight: 5,
                              textTransform: "unset",
                              fontSize: 14,
                            }}
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={() => setOpenModalDelete((prev) => !prev)}
                          >
                            Annuler
                          </Button>
                          <Button
                            style={{ textTransform: "unset", fontSize: 14 }}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => OnConfirmDelete(command?.id)}
                          >
                            Confirmer
                          </Button>
                        </div>
                      </div>
                    }
                  />
                  <CustomModal
                    open={openModal}
                    setOpen={setOpenModal}
                    content={
                      <div className={style.modalImg}>
                        <AppBar
                          sx={{
                            position: "fixed",
                            top: 0,
                            background: "#00000075",
                          }}
                        >
                          <Toolbar
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              sx={stylesX.textClose}
                              onClick={() => setOpenModal((prev) => !prev)}
                            >
                              <Typography>Fermer</Typography>
                            </Button>
                          </Toolbar>
                        </AppBar>
                        {checkTypeFile(command?.img || null) ? (
                          <Iframe
                            loading="auto"
                            url={command?.img || ""}
                            styles={{
                              display: "flex",
                              flex: "1",
                              width: "100%",
                              height: "95vh",
                            }}
                            id=""
                            className=""
                            display="block"
                            position="relative"
                          />
                        ) : (
                          <img
                            src={command?.img || ""}
                            alt="devis"
                            style={{
                              width: "50%",
                              objectFit: "contain",
                              height: "80%",
                            }}
                          />
                        )}
                      </div>
                    }
                    fullScreen
                    dialogProps={{
                      open: openModal,
                      onClose: setOpenModal,
                      PaperProps: {
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        },
                      },
                    }}
                  />
                  <div style={{ width: "80px" }}>
                    {indexCount === 0 && (
                      <Button
                        sx={stylesX.buttonBack}
                        onClick={() => navigate(-1)}
                      >
                        <NavigateBeforeIcon />
                        Retour
                      </Button>
                    )}
                  </div>
                  <div className={style.boxdetails}>
                    <div className={style.rootheader}>
                      <Typography
                        style={{
                          color: "#616161",
                          fontSize: "25px",
                          fontWeight: "600",
                        }}
                      >
                        {`${field} : ${command.id}`}
                      </Typography>
                      <div style={{ paddingLeft: "10px" }}>
                        {activ === "4" &&
                          (info.status === "Libérée" ||
                            info.status === "Expédiée") && (
                            <Button
                              sx={stylesX.buttonStatus}
                              onClick={() => onRenewal(command.id)}
                              variant="contained"
                              color="primary"
                            >
                              Renouveler
                            </Button>
                          )}
                        {command.img && (
                          <Button
                            sx={stylesX.buttonStatus}
                            onClick={() => {
                              setOpenModal((prev) => !prev);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Voir mon document
                          </Button>
                        )}
                        {activ === "2" &&
                          (info?.isValidated ? (
                            <Button
                              size="large"
                              variant="contained"
                              disabled
                              sx={stylesX.validateButton}
                            >
                              Validé
                            </Button>
                          ) : (
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              sx={stylesX.actionValidator}
                              onClick={() =>
                                setOpenModalValidation((prev) => !prev)
                              }
                            >
                              Valider le devis
                            </Button>
                          ))}
                      </div>
                    </div>
                    <div className={style.rootPrepdetails}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>
                            <CustomField
                              label="Date du création"
                              value={moment(command?.datecommande).format(
                                "Do MMM YYYY",
                              )}
                            />
                          </td>
                          {info?.status && (
                            <td>
                              <CustomField
                                label="Statut de la commande"
                                value={info?.status}
                              />
                            </td>
                          )}
                          {startDateRenouvellement && activ === "1" && (
                            <td>
                              <CustomField
                                label="Différée"
                                value={moment(listDatePlanification[0]).format(
                                  "Do MMM YYYY",
                                )}
                              />
                            </td>
                          )}
                          {endDateRenouvellement && activ === "1" && (
                            <td>
                              <CustomField
                                label="Fin du renouvellement"
                                value={moment(
                                  new Date(endDateRenouvellement),
                                ).format("Do MMM YYYY")}
                              />
                            </td>
                          )}
                        </tr>
                      </table>

                      <Divider
                        style={{ width: "100%", margin: "10px 0 10px 0" }}
                      />
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>
                            <CustomField
                              label="Nom du prescritpeur"
                              value={info?.Nom_du_Medecin || "-"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <CustomField
                              label="Nom du patient"
                              value={info?.Nom_du_patient || "-"}
                            />
                          </td>
                          <td>
                            <CustomField
                              label="Type de patient"
                              value={
                                TypePatient.find(
                                  (type) => type.id === info?.Type_de_patient,
                                )?.name || "-"
                              }
                            />
                          </td>
                          <td>
                            <CustomField
                              label="Forme galénique"
                              value={info?.Description || "-"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <CustomField
                              label="Formule de la commande"
                              value={info?.Indications || "-"}
                            />
                          </td>
                          <td>
                            <CustomField
                              label="Commentaire"
                              value={info?.comment || "-"}
                            />
                          </td>
                          {info?.Type_de_patient &&
                            info?.Type_de_patient !== "2" && (
                              <td>
                                <CustomField
                                  label={
                                    info?.Type_de_patient === "1" ||
                                    info?.Type_de_patient === "4"
                                      ? "Poids du Nourrison"
                                      : "Animaux"
                                  }
                                  value={info?.NourissonsOrAnimals || "-"}
                                />
                              </td>
                            )}
                        </tr>
                      </table>

                      <Divider
                        style={{ width: "100%", margin: "10px 0 10px 0" }}
                      />
                      <div className={style.rootThirdItem}>
                        {listDatePlanification && activ === "1" && (
                          <div className={style.containerDateList}>
                            <div className={style.rootListDateItem}>
                              <Typography
                                style={{ color: "#909090", fontSize: "12px" }}
                              >
                                Date du renouvellement
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {listDatePlanification?.map(
                                  (date: string, index: any) => {
                                    return (
                                      <Typography key={`${index?.toString()}s`}>
                                        {date}
                                      </Typography>
                                    );
                                  },
                                )}
                              </div>
                            </div>
                            <div>
                              <Button
                                style={{ textTransform: "none" }}
                                color="error"
                                size="large"
                                variant="contained"
                                onClick={() =>
                                  setOpenModalDelete((prev) => !prev)
                                }
                              >
                                Supprimer
                              </Button>
                              <Button
                                style={{
                                  margin: "0 25px",
                                  textTransform: "none",
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => navigate("edit")}
                              >
                                Modifier
                              </Button>
                            </div>
                          </div>
                        )}
                        {info?.price && (
                          <div className={style.containerPrice}>
                            <Typography>Total</Typography>
                            <div className={style.itemTotal}>
                              {info?.price?.map((item: any) => {
                                return (
                                  <div
                                    className={style.listPrice}
                                    key={item.label}
                                  >
                                    <Typography>{item.label}:</Typography>
                                    <Typography>{item.value}</Typography>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {!loading && !data?.GetListCommandes?.length && <Empty />}
      </div>
    </div>
  );
};

export default DetailsHistory;
