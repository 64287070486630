import { gql } from "@apollo/client";

export const GET_ALL_ORDER = gql`
  query GetAllOrders {
    GetAllOrders {
      id
      status
      orderFormule
      pharmaceuticalForm
      orderDate
      doctorId
      patientId
      files
      customer {
        email
      }
      doctor {
        firstName
        id
        lastName
      }
      patient {
        firstName
        lastName
        id
        type {
          name
        }
      }
    }
  }
`;

export const GET_ALL_TYPEORDER = gql`
  query GetTypeCommande {
    GetTypeCommande {
      id
      nom
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query GetListCommandes($input: InputGetOrder!) {
    GetListCommandes(input: $input) {
      id
      id_typecommande
      id_client
      datecommande
      dateheurecommande
      num_preparation
      data
      img
      typecomande {
        id
        nom
      }
      renouvellement {
        rrule
        id
        taskid
        date
      }
    }
  }
`;

export const DO_RENEWAL = gql`
  query ResendCommand($input: InputResend!) {
    ResendCommand(input: $input) {
      id
    }
  }
`;
